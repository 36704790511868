@import "main";

:root{
    --color: #D15247;
    --color: #3c0352;
    --color: #d9331c;
    --color: #d93f32;
    --color:#2d134a;
    --color:black;
    --accent: #70a9ff;
    --margin:18px;
    --marginX:18px;
    --logo-height:12.1523vw;
    --speed: 200ms;
    --amp_width: 10vw;
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    scroll-behavior: smooth;
}

body{
    background: var(--color);
    color:white;
    font-family: 'Baskerville', serif;
    margin:var(--margin);
    margin-bottom:0px;
}

body * {
    transition-timing-function: ease-in-out;
}

ul{
    list-style-type:none;
    margin:0px;
    padding:0px;
}

a {
    color: inherit;
    text-decoration: inherit;
}
button{
    background:inherit;
    border:inherit;
    color:inherit;
    font-family:inherit;
    font-size:inherit;
    cursor:pointer;
}

a:hover, button:hover{
    color:#82c2ff;
    color:#70a9ff;
    // color:#CE473B;
    // color:#ff7b66;
}

h1,h2,h3,h4,h5,h6{
    font-weight:normal;
}


body.preloading{
    nav{
        top:-100px;
    }
    #logo{
        // opacity:0;
        top:50vh;
        transform:translateY(-50%);
    }
    .main > * > * > *:not(h2){
        opacity:0;
    }
    .main .thumbnails-column, .main #ampersand > *{
        padding-top: calc(1000vh + 36.5px + var(--logo-height));
        opacity: 0;
    }
}

body.loading{
    nav{
        top:-100px;
    }
    #logo{
        // opacity:0;
    }
    .main > * > * > *:not(h2){
        opacity:0;
    }
    .main .thumbnails-column, .main #ampersand > *{
        padding-top: calc(60vh + 36.5px + var(--logo-height));
        opacity:1;
    }
}

img.lazy{
    opacity:0;
    transition: opacity 300ms;
}

img.loaded{
    opacity:1;
}

#logo{
    width:calc(100vw - var(--margin)*2);
    position:fixed;
    top:calc(60px + var(--margin));
    transform:translateY(0);
    // transition: opacity 200ms, top 700ms, transform 700ms;
    z-index: 2;;
    transition: top 300ms, transform 300ms;
}

#logo{
    pointer-events:none;
}

// body.scrolled #logo{
//     opacity:0;
// }



nav{
    
    margin-bottom:var(--margin);
    position:fixed;
    top:var(--margin);
    width:calc(100% - var(--marginX)*2);
    transition: 300ms;
    z-index:1;
    .row{
        display:flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    p:not(:last-child){
        margin-right:calc(var(--margin)/2);
    } 
    // li:not(:last-child){
    //     margin-right:var(--margin);
    // }
    ul{
        display:flex;
        gap: var(--margin);
    }
    .nav-icon{
        position: relative;
        top: 2px;
    }
    svg{
        height: 18px;
        width: 18px;
        position:relative;
        top:3px;
        margin-right: 3px;
    }

    a:hover svg path, a:hover svg circle{
        fill: var(--accent);
    }
    
}

#ampersand-inner{
    position:fixed;
    top: 10px;
    left: 50vw;
    transform:translateX(-50%);
    margin:0;
    font-style:italic;
    font-size: 3.7vw;
}

.contact-info{
    h5{
        font-style: italic;
        font-size: 17px;
        line-height: 1;
        // text-transform: lowercase;
        display:inline-block;
        margin:0px;
        margin-right:calc(var(--margin)/2);
    }
    p{
        font-size: 15px;
        line-height: 1;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        display:inline-block;
        margin:0px;
    }
}

body.scrolled nav{
    top:-100px;
}

.main{
    display:flex;
    flex-wrap: wrap;
    > * {
        position:fixed;
        top:0px;
        height:100vh;
        overflow:scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    > *::-webkit-scrollbar {
        display: none;
    }
  
    .thumbnails-column-outer#architects-scroll{
        left:var(--marginX);
    }
    .thumbnails-column-outer#urbanists-scroll{
        right:var(--marginX);
    }
    .thumbnails-column, #ampersand > *{
        padding-top:calc(45vh + 36.5px + var(--logo-height));
        padding-top:calc(30vh + 36.5px + var(--logo-height));
        width:calc(50vw - var(--margin) - calc(var(--amp_width)/2));
        text-align: justify;
	    text-align-last: justify;
        position:relative;
        transition: top 200ms, padding 700ms, opacity 700ms;
        top:0px;
        // cursor:pointer;
        // transition: padding 200ms;
    }

    .thumbnails-column:after, #ampersand > *:after {
        content: "";
        display: inline-block;
        width: 100%;
    }

    .filler{
        width:100%;
        height: calc(45vh + 36.5px + var(--logo-height));
        height:calc(30vh + 36.5px + var(--logo-height));
        background:red;
    }
    h2{
	    letter-spacing: -0.1em;
        text-transform:uppercase;
        width:99%;
        font-size:3.7vw;
        // position:sticky;
        top:var(--margin);
        margin: 0px 0px 1em 0px;
        line-height:0.65;
        cursor:auto;
        pointer-events: none;
        z-index:1;

        display:flex;
        justify-content: space-between;
        // background:var(--color);
            transition: color 300ms;
    }
    #ampersand{
        text-align:center;
        width:var(--amp_width);
        left: 50vw;
        top:0px;
        transform: translateX(-50%);
        overflow: hidden;
        button{
            text-align:center;
            width: 100%;
            position: sticky;
            top: 15px;
        }
            // top:50vh;
        div{
            width:var(--amp_width);
            // text-align:center;
            // text-align-last: auto;
            // position:fixed;
            // top: 50vh;
            // top: 0px;
            // height:100vh;
        }
        h2{
            font-style:italic;
            justify-content: center;
            margin-bottom:0.5em;
        }
        > *{
            
        }
        // #about-hover{
        //     position: relative;
        //     top: 0.7em;
        //     opacity:0;
        //     transition: 500ms;
        //     transition-timing-function: ease-in-out;
        //     p{
        //         font-style: italic;
        //         max-width:80px;
        //         margin:auto;
        //         margin-bottom:1em;
        //         color:white;
        //     }
        //     span{
        //         text-transform: uppercase;
        //         font-size: 0.8em;
        //         letter-spacing: 0.05em;
        //     }
        // }
        // #about-hover:hover{
        //     transition: 0ms;
        // }
    }

}
body:not(.scrolled) .main {
    .thumbnails-column:hover{
        top:-20px;
        h2{
            // color: var(--accent);
        }
    }
}

.filters{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    margin-bottom:40px;
    // position: sticky;
    // top: 70px;
    z-index: 1;
    button{
        text-transform:uppercase;
        // font-style: italic;
        margin:3px 5px;
        letter-spacing:0.1em;
        font-size:13px;
        transition:0.1s;
        span{
            font-style: italic;
            font-size:16px;
        }
    }
    button:hover{
        // letter-spacing:0.2em;
    }
    button.active{
        color:white;
        opacity:1;
    }

}

.filters.activated {
    button{
        // color:grey;
        color:white;
        opacity: 0.3;
    }
    button.active, button:hover{
        color:white;
        opacity:1;
    }
}

.thumbnails{
    > *{
        margin-bottom:50px;
    }
    img{
        width:100%;
        border-radius:4px;
    }
    h3{
        font-style: italic;
        font-size: 28px;
        line-height: 1.1;
        
        // text-align: justify;
        text-align:center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin:0px;
        margin: 0.5em 0px;
    }
    h4{
        font-style: italic;
        font-size: 22px;
        line-height: 1;
        margin:0px;
    }
    li, li a{
        text-align-last: auto;
        text-align: center;
        height:auto;
        transition: transform 200ms, opacity 200ms, height 200ms;
        transform:scaleY(1);
        opacity: 1;
    }
    li.hidden{
        // display:none;
        overflow:hidden;
        transform:scaleY(0);
        opacity: 0;
    }
}





    /* Hide scrollbar for Chrome, Safari and Opera */
    body.project::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    body.project {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    body.project #images-col::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
      body.project #images-col {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }

body.project{
    #logo{
        opacity:0.1
    }
    #logo:hover{
        opacity:1
    }
    .cols{
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
        padding-top: 60px;
        // align-items:flex-start;
        align-content:flex-start;
        
        > *{
            width:calc(50vw - var(--margin) - var(--amp_width)/2);
        }
    }
    #images-col{
        z-index:1;
        img{
            width:100%;
            margin-bottom:var(--margin);
            border-radius: 4px;
            transition:width 200ms;
            cursor:zoom-in;
        }
        img:last-child{
            margin-bottom:50vh;
        }
        img.zoomed{
            width: calc(100vw - var(--margin)*2);
            cursor:zoom-out;
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    #text-col::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    #text-col {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    #images-col{
        position: fixed;
        left:0;
        height: 100vh;
        // overflow-x: visible;
        overflow-y: scroll;
        // background: red;
        padding-left: var(--margin);
        top:0px;
        padding-top: 80px;
        z-index:-1;
        width: calc(100vw - var(--margin)*2);
        .img-col-inner{
            width: calc(50vw - var(--margin) - var(--amp_width)/2);
        }
    }
    #images-col.zooming{
        // overflow-x: visible;
        // overflow-y: visible;
        z-index:1;
    }
    #text-col{
        padding-right: var(--amp_width);
        width:calc(50vw - var(--margin) - var(--amp_width)*1.5);
        // position:sticky;
        // top: 50px;
        top: 0px;
        position: fixed;
        right: 0px;
        // height: calc(100vh - 50px);
        height: 100vh;
        overflow: scroll;
        #text-col-inner{
            padding-top:30vh;
            padding-bottom:30vh;
        }
        h1{
            font-style:italic;
            text-transform:uppercase;
            letter-spacing:0.2;
            text-align:center;
            letter-spacing:0.15em;
            font-size:2.5rem;
            margin-bottom:1em;
        }
        h2{
            text-align:center;
            font-size:15px;
            text-transform:uppercase;
            letter-spacing:0.15em;
            margin-bottom:100px;
        }
        p, li{
            line-height:1.3;
        }
        .extra-content-section{
            text-align:center;
            h3{
                text-transform: uppercase;
                font-size: 22px;
                letter-spacing: 0.15em;
                font-style: italic;
                margin-top: 3em;
            }
        }
        .tags{
            text-transform:uppercase;
            font-size:13px;
            letter-spacing:0.15em;
        }
    }
    .main{
        pointer-events:none;
    }
    .main .thumbnails-column, .main #ampersand > *{
        padding-top: calc(1000vh + 36.5px + var(--logo-height));
        opacity: 0;
    }
}

body.project.urbanism{
    .cols{
        flex-direction: column-reverse;
        align-content: flex-end;
    }
    #text-col{
        right:auto; 
        left:0px;
        padding-left: var(--amp_width);
        padding-right: 0px;
    }
    #images-col img {
        transform: translateX(0px);
        transition: transform 200ms, width 200ms;
    }
    #images-col img.zoomed {
        transform: translateX(calc(-50% - var(--amp_width)*0.5));
    }
}


#hamburger{
    display:none;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    // margin:0px auto;
    // text-align:center;
    margin: 0;
    padding:0;
}
#mobile-about{
    display:none;
    h5{
        font-style: italic;
        font-size: 17px;
        line-height: 1;
        display: inline-block;
        margin: 0px;
        margin-right: calc(var(--margin)/2);
    }
}
.tablet-only{
    display:none;
}

@media screen and (max-width:930px){
    body.loading{
        nav{
            top:-100px;
        }
    }
    // nav{
    //     flex-wrap:wrap;
    // }
    // nav ul{
    //     width:100%;
    //     display:flex;
    //     justify-content: center;
    //     margin-bottom:5px;
    // }
    nav h5{
        // display:block;
    }

    // nav ul li {
    //     text-align: center;
    //     width: 100%;
    //     display: block;
    // }

    .thumbnails h3{
        font-size: 16px;
        line-height: 1.4;
        margin-top: 0.3em;
    }
    .thumbnails h4{
        font-size: 16px;
        margin-top: 0.3em;
    }
    #about-hover{
        display:none;
    }

    .filters button{
        font-size:11px;

        text-align: center;
        margin: 0px;
    }
    .filters li{
        text-align: center;
        text-align-last: center;
    }
    .filters button span{
        font-size:14px;
    }
    body.preloading nav, body.loading nav, body.scrolled nav {
        top: -100px;
    }
}

@media screen and (max-width:900px){
    nav .row:first-of-type{
        display: block;
        text-align: center;
        width: 100%;
    }
    nav ul{
        justify-content: center;
    }
    nav .row{
        justify-content: center;
        text-align: center;
    }
    .tablet-only{
        display:block;
        position: relative;
        top: -4px;
    }
    .no-tablet{
        display:none;
    }
    #logo{
        top: calc(80px + var(--margin));
    }
}

.filters-btn-mobile{
    display:none;
}
@media screen and (max-width: 650px){
    nav ul{
        display:block;
        margin-bottom:0px;
        display:none;
    }
    nav.mobile-menu-visible{
        ul, .row:first-of-type{
            display:block;
        }
        #mobile-about{
            display:none;
        }
        #hamburger{
            text-align: right;
            width: 100%;
        }
        display: block;
    }

    body.project #text-col #text-col-inner{
        padding-bottom:100px;
        padding-top: 100px;
    }
    body.project #text-col{
        height: auto;
    }
    body.project #images-col{
        position:static;
        padding-left: 0;
        height: auto;
    }
    body.project #images-col .img-col-inner{
        width: 100%;
    }

    #text-close{
        display:none;
    }

    nav.mobile-menu-visible ul{
        display:block;
        // margin-top: 20px;
        // background:black;
    }
    nav.mobile-menu-visible{
        padding-bottom:30px;
        background:rgba(0,0,0,0.8);
        z-index: 100;
        #text-close{ 
            display:inline;
        }
        #text-menu{
            display:none;
        }
        li{
            margin-top:20px;
        }
        nav .row:first-of-type{
            display:block;
        }
    }
    .contact-info p, .contact-info h5{
        display:block;
        line-height:1.3;
    }
    nav ul li {
        text-align: center;
        width: 100%;
        display: block;
        margin-bottom: 5px;
    }
    #logo {
        // top: calc(85px + var(--margin));
        // top: 80px;
        top: 92px;
        transform: translateY(-50%);
    }
    .main h2{
        font-size: 4vw;
    }
    ul.filters{
        display:none;
    }
    .filters-btn-mobile{
        display: block;
        margin: auto;
        margin-bottom: 2em;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-size: 12px;
        color: var(--accent);
    }
    ul.filters.visible{
        display:block;
    }
    body.preloading nav, body.loading nav, body.scrolled nav {
        top: -100px;
    }

    body.project #images-col, body.project #text-col{
        width:100%;
    }
    body.project #images-col{
        order:2;
    }
    body.project.urbanism .cols{
        // display:block;
        // flex-direction:row-reverse;
    }
    body.project #text-col{
        width: calc(100% - var(--amp_width)*2);
        padding-left: var(--amp_width);
        padding-right: var(--amp_width);
        position:static;
        order: 1;
    }
    body.project.urbanism .cols {
        flex-direction: row;
        align-content: flex-end;
    }
    
    #hamburger, #mobile-about{
        display:block;
    }
    nav{ 
        display:flex;
        justify-content: space-between;
        .row, .row:first-of-type{
            display:none;
        }
    }

}

@media screen and (max-width: 430px){
    nav h5{
        display:block;
    }
    // #logo {
    //     top: calc(140px + var(--margin));
    // }
    body.preloading nav, body.loading nav, body.scrolled nav{
        top: -160px;
    }
}




@media screen and (min-width: 1150px) {
    .filters button {
        font-size: 1.13vw;
    }

    .filters button span {
        font-size: 1.3913vw;
    }
}

@media screen and (min-width: 1500px) {
    body.project #text-col h1{
        font-size:2.67vw;
    }
    body.project #text-col .extra-content-section h3{
        font-size: 1.467vw;
    }
    body.project #text-col p, body.project #text-col li{
        font-size: 1.067vw;
    }
    body.project #text-col .tags li{
        font-size: 0.867vw;
    }
}

.capLetter{
    font-style: italic;
    font-size: 1.4em;
}

.amp{
    font-style: italic;
}


.easter{
    margin-top:100px;
    border-top:1px solid white;
    width:100%;
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content: center;
    .inner{
        text-align:center;
        font-size:3rem;
        display: flex;
        justify-content: center;
        user-select: none;
        background:black;
        div:first-child, div:last-child{
            width:40vw;
        }
        div:first-child{text-align:right;}
        div:last-child{text-align:left;}
    }

    .inner:hover{
        background:black;
    }
}